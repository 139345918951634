import { Block } from '@/services/ecommerce/types/blocksTypes';
import ActionsBlock from '../../dynamics/Actions';
import BannerBlock from '../../dynamics/BannerBlock';
import CarouselBlock from '../../dynamics/CarouselBlock';
import CategoryBlock from '../../dynamics/Category';
import FunctionBlock from '../../dynamics/FunctionBlock';
import ImageBlock from '../../dynamics/ImageBlock';
import SideScrollBlock from '../../dynamics/SideScrollBlock';
import TextBlock from '../../dynamics/TextBlock';
import VideoBlock from '../../dynamics/VideoBlock';
import { get } from 'lodash';

interface Props {
	data: any;
	params?: any;
	titleParams?: any;
	start?: number;
}

const BlockContents = ({ data, params, titleParams, start }: Props) => {
	const blockTypeComponents: any = {
		product: (data: Block, i: number) => <SideScrollBlock key={i} sl={i} data={data} titleParams={titleParams} />,
		actions: (data: Block, i: number) => <ActionsBlock key={i} sl={i} data={data} />,
		carousel: (data: Block, i: number) => <CarouselBlock data={data} key={i} sl={i} />,
		category: (data: Block, i: number) => <CategoryBlock key={i} sl={i} data={data} />,
		function: (data: Block, i: number) => (
			<FunctionBlock
				key={i}
				sl={i}
				data={
					{
						...data,
						extra: params || {}
					} as Block
				}
				titleParams={titleParams}
			/>
		),
		banner: (data: Block, i: number) => <BannerBlock key={i} sl={i} data={data} />,
		video: (data: Block, i: number) => <VideoBlock key={i} sl={i} data={data} />,
		image: (data: Block, i: number) => <ImageBlock data={data} key={i} sl={i} />,
		side_scroll: (data: any, i: number) => <SideScrollBlock key={i} sl={i} data={data} />,
		text: (data: Block, i: number) => <TextBlock data={data} key={i} sl={i} />
	};

	return data?.blocks
		?.slice()
		.sort((a: any, b: any) => (a.block_weight || 0) - (b.block_weight || 0))
		.map((data: any, i: number) => {
			const blockType = data?.block_type;
			const block_subtitle = HandleDynamicSubtitles(data);
			data.block_subtitle = block_subtitle;
			const componentRenderer = blockTypeComponents[blockType];
			if (componentRenderer) {
				const sl = start ? start + i : i;
				return data ? componentRenderer(data, sl) : '';
			}
			return null;
		});
};

export default BlockContents;

export const BlockContent = ({ data, params, titleParams, start }: Props) => {
	const blockTypeComponents: any = {
		product: (data: Block, i: number) => <SideScrollBlock key={i} sl={i} data={data} titleParams={titleParams} />,
		actions: (data: Block, i: number) => <ActionsBlock key={i} sl={i} data={data} />,
		carousel: (data: Block, i: number) => <CarouselBlock data={data} key={i} sl={i} />,
		category: (data: Block, i: number) => <CategoryBlock key={i} sl={i} data={data} />,
		function: (data: Block, i: number) => (
			<FunctionBlock
				key={i}
				sl={i}
				data={
					{
						...data,
						extra: params || {}
					} as Block
				}
				titleParams={titleParams}
			/>
		),
		banner: (data: Block, i: number) => <BannerBlock key={i} sl={i} data={data} />,
		video: (data: Block, i: number) => <VideoBlock key={i} sl={i} data={data} />,
		image: (data: Block, i: number) => <ImageBlock data={data} key={i} sl={i} />,
		side_scroll: (data: any, i: number) => <SideScrollBlock key={i} sl={i} data={data} />,
		text: (data: Block, i: number) => <TextBlock data={data} key={i} sl={i} />
	};

	const blockType = data?.block_type;
	const componentRenderer = blockTypeComponents[blockType];
	if (componentRenderer) {
		const sl = start || 0;
		return data ? componentRenderer(data, sl) : null;
	}
	return null;
};

const HandleDynamicSubtitles = (data) => {
	try {
		const { block_subtitle = '' } = data; // Default to empty string if not provided
		const ruleset = {
			percentage: () => {
				let percentage = 0;
				try {
					const pv = get(data, 'block_data[0].pv[0]', {});
					const { pv_b2c_price: price = 0, pv_b2c_mrp: mrp = 0 } = pv;
					if (!isNaN(mrp) && !isNaN(price) && mrp > 0) {
						const discountPercentage = ((mrp - price) / mrp) * 100;
						percentage = Math.round(discountPercentage);
					}
				} catch (innerError) {}
				return `<strong>${percentage}%</strong>`;
			}
		};

		// Replace parameters in the subtitle
		const replacedSubtitle = replaceParams(block_subtitle, ruleset);
		return replacedSubtitle; // Return the processed subtitle
	} catch (error) {
		console.error('Error in HandleDynamicSubtitles:', error);
		return ''; // Return a default value in case of failure
	}
};

// Helper function to replace parameters
const replaceParams = (text, ruleset) => {
	try {
		return text.replace(/\{(.*?)\}/g, (match, paramName) => {
			if (ruleset[paramName]) {
				// Get value or execute function to get dynamic value
				return typeof ruleset[paramName] === 'function' ? ruleset[paramName]() : ruleset[paramName];
			}
			// If no replacement rule found, keep the placeholder
			return match;
		});
	} catch (error) {
		console.error('Error in replaceParams:', error);
		return text; // Return the original text if replacement fails
	}
};
