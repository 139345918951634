import { getImage } from '@/services/ecommerce/utils/helper';
import { has } from 'lodash';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

interface Props {
	data: any;
	label: string;
	links?: any;
}

const Image3Column: React.FC<Props> = ({ data, label, links }) => {
	const { children } = links;
	return (
		<div className='my-30'>
			<div className='container'>
				<div
					className='text-center '
					style={{
						textAlign: 'center',
						marginBottom: 30
					}}>
					<h2 className='text-gray900 text-30 fw-600'>{label}</h2>
				</div>
				<div className='grid grid-cols-3 gap-20'>
					{data?.map((d: any, i: number) => {
						return (
							<Link key={i} href={has(children, [i, 'link']) ? children[i].link : '/'}>
								<Image
									className='cursor-pointer'
									alt=''
									src={getImage(d?.file)}
									width={d.width || 80}
									height={d.height || 80}
									style={{
										height: '100%',
										width: '100%'
									}}
									unoptimized
									priority
									quality={100}
								/>
							</Link>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Image3Column;
